/**
 * Toggle required email if 'メール' checkbox is checked
 */
const toggleRequiredEmail = () => {
  const toggleEmailValue = document.querySelector('.js-toggle-email') as HTMLElement
  const toggleEmailInput = document.querySelectorAll('.js-toggle-email input[type="checkbox"]')
  const toggleEmailInputRadio = document.querySelectorAll('.js-toggle-email input[type="radio"]')
  const email = document.querySelector('.js-email')
  const fax = document.querySelector('.js-fax')
  const confirm = document.querySelector('form .mw_wp_form_confirm')

  if(toggleEmailValue) {
    if(confirm) {
      let innerHTMLConfirm = toggleEmailValue.innerHTML
      let toggleEmailValueString = String(toggleEmailValue.dataset.value)

      if(innerHTMLConfirm.search(toggleEmailValueString) >= 1) {
        if(email) {
          email.classList.add('required') 
        }
      }

      let innerHTMLConfirmSecondary = toggleEmailValue.innerHTML
      let toggleEmailValueStringSecondary = String(toggleEmailValue.dataset.secondaryvalue)

      if(innerHTMLConfirmSecondary.search(toggleEmailValueStringSecondary) >= 1) {
        if(fax) {
          fax.classList.add('required') 
        }
      }
    }

    if(toggleEmailInput) {
      toggleEmailInput.forEach(item => {
        let itemElement = item as HTMLInputElement
  
        if(itemElement.value === toggleEmailValue.dataset.value) {
          itemElement.addEventListener('click', () => {
            if(email) {
              if(itemElement.checked) {
                email.classList.add('required')
              } else {
                email.classList.remove('required')
              }
            }
          })
  
          if(email) {
            if(itemElement.checked) {
              email.classList.add('required')
            } else {
              email.classList.remove('required')
            }
          }
        }
  
        if(itemElement.value === toggleEmailValue.dataset.secondaryvalue) {
          itemElement.addEventListener('click', () => {
            if(fax) {
              if(itemElement.checked) {
                fax.classList.add('required')
              } else {
                fax.classList.remove('required')
              }
            }
          })
  
          if(fax) {
            if(itemElement.checked) {
              fax.classList.add('required')
            } else {
              fax.classList.remove('required')
            }
          }
        }
      })
    }

    if(toggleEmailInputRadio) {
      toggleEmailInputRadio.forEach(item => {
        let itemElement = item as HTMLInputElement
  
        itemElement.addEventListener('change', () => {
          if(email) {
            if(itemElement.checked) {
              if(itemElement.value === toggleEmailValue.dataset.value) {
                email.classList.add('required')
              } else {
                email.classList.remove('required')
              }
            }
          }
        })
  
        if(email) {
          if(itemElement.checked) {
            if(itemElement.value === toggleEmailValue.dataset.value) {
              email.classList.add('required')
            } else {
              email.classList.remove('required')
            }
          }
        }
      })
    }
  }
}

export default toggleRequiredEmail
