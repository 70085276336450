/**
 * Toggle kyosai-menu for mutual-aid pages
 */
const kyosai = () => {
  const toggle = document.querySelector('.js-kyosai')

  if(toggle) {
    const menu = toggle.nextElementSibling
    toggle.addEventListener('click', function() {
      menu?.classList.toggle('active')
      document.body.classList.toggle('overflow')
    })
    if(window.matchMedia("(max-width: 767px)").matches) {
      const links = menu?.querySelectorAll('a')
      links?.forEach(link=>{
        link.addEventListener('click', function() {
          menu?.classList.toggle('active')
          document.body.classList.toggle('overflow')
        })
      })
    }
  }
}

export default kyosai