  /**
 * Toggle input text
 */
const mwwp = () => {
  const checkboxType = document.querySelector('.js-checkbox-type') as HTMLInputElement;
  const checkboxType2 = document.querySelector('.js-checkbox2-type') as HTMLInputElement;
  const inputType = document.querySelector('.js-input-type') as HTMLInputElement;
  const textArea = document.querySelector('.js-textarea-type') as HTMLInputElement;

  checkboxType?.addEventListener('change', ()=> {
    if (checkboxType.checked) {
      inputType?.classList.add('active');
      textArea?.classList.add('active');
    } else {
      inputType.value = '';
      inputType?.classList.remove('active');
      textArea.value = '';
      textArea?.classList.remove('active');
    }
  })

  if(checkboxType) {
    if (checkboxType.checked) {
      inputType?.classList.add('active');
      textArea?.classList.add('active');
    } else {
      inputType.value = '';
      inputType?.classList.remove('active');
      if(textArea) {
        textArea.value = '';
        textArea.classList.remove('active');
      }
    }
  }

  checkboxType2?.addEventListener('change', ()=> {
    if (checkboxType2.checked) {
      inputType?.classList.add('active');
    } else {
      inputType.value = '';
      inputType?.classList.remove('active');
    }
  })

  if(checkboxType2) {
    if (checkboxType2.checked) {
      inputType?.classList.add('active');
    } else {
      inputType.value = '';
      inputType?.classList.remove('active');
    }
  }

  const radio1 = document.querySelector('.js-radio1') as HTMLInputElement;
  const radio2 = document.querySelector('.js-radio2') as HTMLInputElement;
  const radioText = document.querySelector('.js-radio-text') as HTMLInputElement;
  
  if(radio1) {
    if(radio1.checked) {
      if(radioText) {
        radioText.value= '';
        radioText.classList.remove('active')
      }
    }

    radio1.addEventListener('click', ()=> {
      if(radio1.checked && radioText) {
        radioText.value= '';
        radioText.classList.remove('active')
      }
    })
  }

  if(radio2) {
    if(radio2.checked) {
      if(radioText) {
        radioText.classList.add('active')
      }
    }

    radio2.addEventListener('click', ()=> {
      if(radio2.checked && radioText) {
        radioText.classList.add('active')
      }
    })
  }
}

export default mwwp