/**
 * Report form condition
 */
const report = () => {
  const cbx = document.querySelectorAll('.js-report-type-cbx input[type="radio"]');
  const items = document.querySelectorAll('.js-report-type-hide');
  const cbxOption1 = document.querySelector('.js-checkbox-1 input') as HTMLInputElement;
  const cbxOption2 = document.querySelector('.js-checkbox-2 input') as HTMLInputElement;
  const inputText = document.querySelectorAll('.js-report-type-cbx input[type="text"');

  if(cbx) {
    cbx.forEach(item => {
      item.addEventListener('change', () => {
        let itemElement = item as HTMLInputElement
        if(itemElement.checked) {
          if(itemElement.value === '共済契約者本人') {
            items.forEach(item => {
              item.classList.add('hidden');
            })
            inputText.forEach(item => {
              let itemElementInner = item as HTMLInputElement

              if(itemElementInner) {
                itemElementInner.value = ''
              }
            })
          } else if(itemElement.value === 'ご家族') {
            inputText.forEach(item => {
              let itemElementInner = item as HTMLInputElement

              if(itemElementInner.name === 'type_text2') {
                itemElementInner.value = ''
              }
            })

            items.forEach(item => {
              item.classList.remove('hidden');
            })
          } else {
            items.forEach(item => {
              item.classList.remove('hidden');
            })

            inputText.forEach(item => {
              let itemElementInner = item as HTMLInputElement

              if(itemElementInner.name === 'type_text1') {
                itemElementInner.value = ''
              }
            })
          }
        }
      })
    })
  }

  if(cbx) {
    cbx.forEach(item => {
      let itemElement = item as HTMLInputElement
      if(itemElement.checked) {
        if(itemElement.value === '共済契約者本人'){
          items.forEach(item => {
            item.classList.add('hidden');
          })
        } else {
          items.forEach(item => {
            item.classList.remove('hidden');
          })
        }
      }
    })
  }

  cbxOption1?.addEventListener('change', ()=> {
    if(cbxOption1.checked) {
      cbxOption2.checked = false;
    }
  })

  cbxOption2?.addEventListener('change', ()=> {
    if(cbxOption2.checked) {
      cbxOption1.checked = false;
    }
  })
  
  const confirmPage = document.querySelector('.mw_wp_form_confirm');
  const cbxContent = document.querySelector('.js-report-type-cbx .form__cbx-list')?.textContent;
  const parsedCheckboxStr = cbxContent?.replace(/\s/g, '');
  const typeTextHidden = document.querySelector('input[name="type_text1"]') as HTMLInputElement
  const relationship = document.querySelector('.js-relationship')

  if(confirmPage) {
    if(parsedCheckboxStr === '共済契約者本人' ) {
      items.forEach(item=>{
        item.classList.add('hidden');
      })
    } else {
      items.forEach(item=>{
        item.classList.remove('hidden');
      })
    }

    if(typeTextHidden && typeTextHidden.value === '') {
      if(relationship) {
        relationship.classList.add('hidden')
      }
    }
  }
}

export default report
