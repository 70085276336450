/**
 * Disable input text of others checkbox unless checked
 */
const disableInputTextOthersUnchecked = () => {
  const parentContainer = document.querySelectorAll('.mw_wp_form_input .js-has-content')

  if(parentContainer) {
    parentContainer.forEach(item => {
      let inputElement = item.querySelector('input[type="text"]') as HTMLInputElement
      let inputNumberedElement = item.querySelector('input[type="number"]') as HTMLInputElement
      let inputSelectResetText = item.querySelector('.ui-selectmenu-text')
      let selectElement = item.querySelector('select') as HTMLSelectElement
      let itemElement = item as HTMLElement

      if(inputElement)
        inputElement.disabled = true

      if(inputNumberedElement)
        inputNumberedElement.disabled = true

      if(selectElement) {
        selectElement.disabled = true
      }

      let inputCheckbox = item.querySelector('input[type="checkbox"]') as HTMLInputElement

      if(inputCheckbox) {
        if(inputCheckbox.checked) {
          if(inputElement)
            inputElement.disabled = false
          
          if(inputNumberedElement)
            inputNumberedElement.disabled = false

          if(selectElement)
            selectElement.disabled = false
        }

        inputCheckbox.addEventListener('click', () => {
          if(inputCheckbox.checked) {
            if(inputElement)
              inputElement.disabled = false

            if(inputNumberedElement)
              inputNumberedElement.disabled = false

            if(selectElement)
              selectElement.disabled = false
          } else {
            if(inputElement)
              inputElement.disabled = true

            if(inputNumberedElement)
              inputNumberedElement.disabled = true

            if(selectElement)
              selectElement.disabled = true
          }
        })
      }

      let mainParent = item.parentElement

      if(mainParent) {
        let inputRadio = mainParent.querySelectorAll('input[type="radio"]')

        if(inputRadio) {
          inputRadio.forEach(radioItem => {
            let currentCheckedElement = mainParent?.querySelector('input[type="radio"]:checked') as HTMLInputElement

            if(currentCheckedElement) {
              let valueCheckedElement = currentCheckedElement.value

              if(valueCheckedElement == itemElement.dataset.value) {
                if(inputElement)
                  inputElement.disabled = false

                if(inputNumberedElement)
                  inputNumberedElement.disabled = false

                if(selectElement)
                  selectElement.disabled = false
              } else {
                if(inputElement)
                  inputElement.disabled = true

                if(inputNumberedElement)
                  inputNumberedElement.disabled = true

                if(selectElement)
                  selectElement.disabled = true
              }
            }

            radioItem.addEventListener('change', () => {
              let currentCheckedElement = mainParent?.querySelector('input[type="radio"]:checked') as HTMLInputElement
              let valueCheckedElement = currentCheckedElement.value

              if(valueCheckedElement == itemElement.dataset.value) {
                if(inputElement)
                  inputElement.disabled = false

                if(inputNumberedElement)
                  inputNumberedElement.disabled = false

                if(selectElement)
                  selectElement.disabled = false
              } else {
                if(inputElement)
                  inputElement.disabled = true

                if(inputNumberedElement)
                  inputNumberedElement.disabled = true

                if(selectElement)
                  selectElement.disabled = true
              }
            })
          })
        }
      }

      if(selectElement) {
        if(selectElement.disabled) {
          selectElement.selectedIndex = 0
  
          if(inputSelectResetText) {
            inputSelectResetText.innerHTML = '等級を選択'
          }
        }
      }
    })
  }
}

export default disableInputTextOthersUnchecked
