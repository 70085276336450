import ajaxZip from "./components/ajaxZip"
import customSelect from "./components/customSelect"
import jqueryUI from "./components/jqueryUI"
import kyosai from "./components/kyosai"
import report from "./components/report"
import searchMenu from "./components/searchMenu"
import selectDate from "./components/selectDate"
import disableInputTextOthersUnchecked from "./components/disableInputTextOthersUnchecked"
import toggleRequiredEmail from "./components/toggleRequiredEmail"
import mwwp from "./components/mwwp"


document.addEventListener(
  'DOMContentLoaded',
  () => {
    mwwp()
    searchMenu()
    selectDate()
    customSelect()
    ajaxZip()
    jqueryUI()
    report()
    kyosai()
    disableInputTextOthersUnchecked()
    toggleRequiredEmail()
  },
  false
)
