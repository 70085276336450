/**
 * Ajax Zip
 */
const ajaxZip = () => {
  const zip = document.querySelector('input[name=postcode]')

  if (zip) {
    zip.addEventListener('keyup', function () {
      // @ts-ignore
      AjaxZip3.zip2addr('postcode', '', 'address','address')
    })
  }
}

export default ajaxZip