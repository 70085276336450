/**
 * Initialize JQuery UI
 */
//@ts-nocheck
const jqueryUI = () => {
  const form = document.querySelector('.mw_wp_form')

  if(form) {
    
    // Create a variable for adding minDate
    let min = 7;

    // Set datepicker placeholder
    const datepicker = document.querySelectorAll('.js-datepicker input')
    const datepicker2 = document.querySelectorAll('.js-datepicker-2 input')

    if(datepicker) {
      let today = new Date()
      const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
      let day = nextWeek.getDay();
      let addDays = 0;

      let formattedDate = $.datepicker.formatDate('yy-mm-dd', nextWeek);
      let japaneseHolidays = calculateJapaneseHolidays();

      if(japaneseHolidays.includes(formattedDate)) {
        addDays += 1;
        new Date(nextWeek.setDate(nextWeek.getDate() + 1));
        day = nextWeek.getDay()
      }

      if(day === 6) {
        addDays = 2;
      } else if(day === 0) {
        addDays = 1;
      }

      new Date(nextWeek.setDate(nextWeek.getDate() + addDays));

      //datepicker 2

      let min2 = 1;

      const nextWeek2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

      const formatter = new Intl.DateTimeFormat("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })

      datepicker.forEach(picker => {
        picker?.setAttribute('placeholder', formatter.format(nextWeek))
        picker?.setAttribute('readonly', "readonly")
      })

      if(datepicker2) {
        datepicker2.forEach(picker => {
          picker?.setAttribute('placeholder', formatter.format(nextWeek2))
          picker?.setAttribute('readonly', "readonly")
        })
      }

      function calculateJapaneseHolidays() {
        let holidays = [];

        // Calculate the holiday dates for the given year
        holidays.push('2023-01-01');
        holidays.push('2023-02-11');
        holidays.push('2023-02-23');
        holidays.push('2023-04-29');
        holidays.push('2023-05-03');
        holidays.push('2023-05-04');
        holidays.push('2023-05-05');
        holidays.push('2023-08-11');
        holidays.push('2023-11-03');
        holidays.push('2023-11-23');

        holidays.push('2024-01-01');
        holidays.push('2024-02-11');
        holidays.push('2024-02-23');
        holidays.push('2024-04-29');
        holidays.push('2024-05-03');
        holidays.push('2024-05-04');
        holidays.push('2024-05-05');
        holidays.push('2024-08-11');
        holidays.push('2024-11-03');
        holidays.push('2024-11-23');

        holidays.push('2023-01-02');
        holidays.push('2023-01-09');
        holidays.push('2023-03-21');
        holidays.push('2023-07-17');
        holidays.push('2023-09-18');
        holidays.push('2023-09-23');
        holidays.push('2023-10-09');

        holidays.push('2024-01-08');
        holidays.push('2024-02-12');
        holidays.push('2024-03-20');
        holidays.push('2024-05-06');
        holidays.push('2024-07-15');
        holidays.push('2024-08-12');
        holidays.push('2024-09-16');
        holidays.push('2024-09-22');
        holidays.push('2024-09-23');
        holidays.push('2024-10-14');
        holidays.push('2024-11-04');

        return holidays;
      }

      function setHolidays(date) {
        let formattedDate = $.datepicker.formatDate('yy-mm-dd', date);
        let japaneseHolidays = calculateJapaneseHolidays();
        let noWeekend = $.datepicker.noWeekends(date);

        return noWeekend[0] ? (($.inArray(formattedDate, japaneseHolidays) < 0) ? [true] : [false]) : noWeekend;
      }

      // datepicker
      $(".js-datepicker input").datepicker({
        closeText: "閉じる",
        prevText: "&#x3C;前",
        nextText: "次&#x3E;",
        currentText: "今日",
        monthNames: [ "1月", "2月", "3月", "4月", "5月", "6月","7月", "8月", "9月", "10月", "11月", "12月" ],
        monthNamesShort: [ "1月", "2月", "3月", "4月", "5月", "6月","7月", "8月", "9月", "10月", "11月", "12月" ],
        dayNames: [ "日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日" ],
        dayNamesShort: [ "日", "月", "火", "水", "木", "金", "土" ],
        dayNamesMin: [ "日", "月", "火", "水", "木", "金", "土" ],
        weekHeader: "週",
        dateFormat: "yy/mm/dd",
        firstDay: 0,
        isRTL: false,
        showMonthAfterYear: true,
        yearSuffix: "年",
        beforeShowDay: setHolidays,
        minDate: 0 + min
      });

      // datepicker2
      $(".js-datepicker-2 input").datepicker({
        closeText: "閉じる",
        prevText: "&#x3C;前",
        nextText: "次&#x3E;",
        currentText: "今日",
        monthNames: [ "1月", "2月", "3月", "4月", "5月", "6月","7月", "8月", "9月", "10月", "11月", "12月" ],
        monthNamesShort: [ "1月", "2月", "3月", "4月", "5月", "6月","7月", "8月", "9月", "10月", "11月", "12月" ],
        dayNames: [ "日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日" ],
        dayNamesShort: [ "日", "月", "火", "水", "木", "金", "土" ],
        dayNamesMin: [ "日", "月", "火", "水", "木", "金", "土" ],
        weekHeader: "週",
        dateFormat: "yy/mm/dd",
        firstDay: 0,
        isRTL: false,
        showMonthAfterYear: true,
        yearSuffix: "年",
        minDate: 0 + min2,
        onSelect: function(dateText) {
          $(this).addClass('change');
        }
      });

      // Select menu
      $( "select" ).selectmenu();
    }
  }
}

export default jqueryUI