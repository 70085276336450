/**
 * Search anchor link
 */

//@ts-nocheck
const searchMenu = () => {
  const searchMenu = document.querySelectorAll(".js-search-menu");

  if(searchMenu) {
    for (let i = 0; i < searchMenu.length; i++) {
      const fieldSelect = searchMenu[i].querySelector("select");
  
      /* create new DIV for selected item */
      const selectedItem = document.createElement("DIV");
      selectedItem.setAttribute("class", "select__selected");
      selectedItem.innerHTML = fieldSelect.options[fieldSelect.selectedIndex].innerHTML;
      searchMenu[i].appendChild(selectedItem);
  
      /* create new DIV to contain the options */
      const optionsList = document.createElement("DIV");
      optionsList.setAttribute("class", "select__options select-hide");
      
      for (let j = 1; j < fieldSelect.length; j++) {
        /* create new ANCHOR LINK for each select option */
        const optionsItem = document.createElement("a");
        optionsItem.setAttribute("href", "#"+fieldSelect.options[j].value);
        optionsItem.setAttribute("class", "select__options-item");
        optionsItem.innerHTML = fieldSelect.options[j].innerHTML;
  
        /* update selected item on both custom and original select boxes */
        for (let k = 0; k < fieldSelect?.length; k++) {
          optionsItem.addEventListener("click", function(e) {
            if (fieldSelect.options[k].innerHTML == this.innerHTML) {
              fieldSelect.selectedIndex = k;
              selectedItem.innerHTML = this.innerHTML;
    
              const selectedSame = this.parentNode?.querySelectorAll(".same-as-selected");
              for (let l = 0; l < selectedSame.length; l++) {
                selectedSame[l].classList.remove("same-as-selected");
              }
              this.setAttribute("class", "select__options-item same-as-selected");
            }
            selectedItem.click();
          });
        }
        optionsList.appendChild(optionsItem);
      }
      searchMenu[i].appendChild(optionsList);
  
      /* toggle custom selectbox and close other selectbox instances */
      selectedItem.addEventListener("click", function(e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-active");
      });
    }
  
    /* close all selectbox instances except current custom selectbox */
    function closeAllSelect(elmnt) {
      var arrNo = [];
      const optionsList = document.getElementsByClassName("select__options");
      const selectedItem = document.getElementsByClassName("select__selected");
      
      for (let i = 0; i < selectedItem.length; i++) {
        if (elmnt == selectedItem[i]) {
          arrNo.push(i)
        } else {
          selectedItem[i].classList.remove("select-active");
        }
      }
  
      for (let i = 0; i < optionsList.length; i++) {
        if (arrNo.indexOf(i)) {
          optionsList[i].classList.add("select-hide");
        }
      }
    }
  
    /* close all selectbox instances when clicked outside of element */
    document.addEventListener("click", closeAllSelect);
  }
}

export default searchMenu