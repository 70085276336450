/**
 * Populate select box with date
 */
//@ts-nocheck
const selectDate = () => {
  const selects = document.querySelectorAll('.js-select-date')

  if(selects) {
    selects.forEach(item => {
      const selectYear = item.querySelector('.js-select-year') as HTMLSelectElement
      const selectMonth = item.querySelector('.js-select-month') as HTMLSelectElement
      const selectDay = item.querySelector('.js-select-day') as HTMLSelectElement
      const date = new Date()
      var month = 1
      var year = 0

      //populate year with current +8
      if(selectYear) {
        let currentYear = new Date().getFullYear()
        var countYear = 3
        if(selectYear.classList.contains('js-birthyear')) {
          countYear = 60
        }

        for(let i = 0; i <= countYear; i++) {
          var year = currentYear - i
          if(selectYear.classList.contains('js-birthyear')) {
            year = currentYear - i
          }

          let option = document.createElement("option")
          option.text = year.toString()
          option.value = year.toString()
          selectYear?.appendChild(option)
        }

        const inputHiddenYear = document.querySelector('input[name="accident_year_hidden"]') as HTMLInputElement

        if(inputHiddenYear.value)
          selectYear.value = inputHiddenYear.value

        $('.js-select-year').on('selectmenuchange', function() {
          if(inputHiddenYear) {
            inputHiddenYear.value = selectYear.value
          }
        });
      }

      //populate month
      if(selectMonth) {
        let countMonth = 12;
        for(let i = 1; i <= countMonth; i++) {
          let option = document.createElement("option")
          option.text = i.toString()
          option.value = i.toString()
          selectMonth?.appendChild(option)
        }

        const inputHiddenMonth = document.querySelector('input[name="accident_month_hidden"]') as HTMLInputElement

        if(inputHiddenMonth.value)
          selectMonth.value = inputHiddenMonth.value

        $('.js-select-month').on('selectmenuchange', function() {
          if(inputHiddenMonth) {
            inputHiddenMonth.value = selectMonth.value
          }
        });
      }

      //populate day
      function clearDay() {
        for (let i = (selectDay.options.length - 1); i > 0; i--) {
          selectDay.remove(i);
        }
        selectDay.selectedIndex = 0;
      }

      //@ts-ignore
      function getDay (month, year) {
        return new Date(year, month, 0).getDate();
      }

      function populateDay() {
        let countDay = getDay(month, year)
        for(let i = 1; i <= countDay; i++) {
          let option = document.createElement("option")
          option.text = i.toString()
          option.value = i.toString()
          selectDay?.appendChild(option)
        }
      }

      populateDay();

      const inputHiddenDay = document.querySelector('input[name="accident_day_hidden"]') as HTMLInputElement

      if (selectDay) {
        if (inputHiddenDay.value) {
          selectDay.value = inputHiddenDay.value;
        }
      
        $('.js-select-day').on('selectmenuchange', function() {
          if (inputHiddenDay) {
            inputHiddenDay.value = selectDay.value;
          }
        });
      }
    })
  }
}

export default selectDate