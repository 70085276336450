/**
 * Custom select
 */

//@ts-nocheck
const customSelect = () => {
  const customSelect = document.querySelectorAll('.js-select');

  if(customSelect) {
    for (let i = 0; i < customSelect.length; i++) {
      const fieldSelect = customSelect[i].querySelector('select');
      
      if(fieldSelect) {
        /* create new DIV for selected item */
        const selectedItem = document.createElement('DIV');
        const selectedItemText = fieldSelect.options[fieldSelect.selectedIndex].innerHTML;
        selectedItem.setAttribute('class', 'select__selected');
        if(fieldSelect?.selectedIndex==0) {
          selectedItem.innerHTML = '<span class="select-disabled">'+selectedItemText+'<span>';
        } else {
          selectedItem.innerHTML = '<span>'+selectedItemText+'<span>';
        }
        customSelect[i].appendChild(selectedItem);
    
        /* create new DIV to contain the options */
        const optionsList = document.createElement('DIV');
        optionsList.setAttribute('class', 'select__options select-hide');
        
        for (let j = 1; j < fieldSelect.length; j++) {
          const optionsItem = document.createElement('DIV');
          optionsItem.setAttribute('class', 'select__options-item');
          optionsItem.innerHTML = fieldSelect.options[j].innerHTML;
    
          /* update selected item on both custom and original select boxes */
          for (let k = 0; k < fieldSelect?.length; k++) {
            optionsItem.addEventListener('click', function(e) {
              if (fieldSelect.options[k].innerHTML == this.innerHTML) {
                fieldSelect.selectedIndex = k;
                selectedItem.innerHTML = this.innerHTML;
      
                const selectedSame = this.parentNode?.querySelectorAll('.same-as-selected');
                for (let l = 0; l < selectedSame.length; l++) {
                  selectedSame[l].classList.remove('same-as-selected');
                }
                this.setAttribute('class', 'select__options-item same-as-selected');
              }
              selectedItem.click();
            });
          }
          optionsList.appendChild(optionsItem);
        }
        customSelect[i].appendChild(optionsList);
    
        /* toggle custom selectbox and close other selectbox instances */
        selectedItem.addEventListener('click', function(e) {
          e.stopPropagation();
          closeAllSelect(this);
          this.nextSibling.classList.toggle('select-hide');
          this.classList.toggle('select-active');
        });
      }
    }
  
    /* close all selectbox instances except current custom selectbox */
    function closeAllSelect(elmnt) {
      var arrNo = [];
      const optionsList = document.getElementsByClassName('select__options');
      const selectedItem = document.getElementsByClassName('select__selected');
      
      for (let i = 0; i < selectedItem.length; i++) {
        if (elmnt == selectedItem[i]) {
          arrNo.push(i)
        } else {
          selectedItem[i].classList.remove('select-active');
        }
      }
  
      for (let i = 0; i < optionsList.length; i++) {
        if (arrNo.indexOf(i)) {
          optionsList[i].classList.add('select-hide');
        }
      }
    }
  
    /* close all selectbox instances when clicked outside of element */
    document.addEventListener('click', closeAllSelect);
  }
}

export default customSelect